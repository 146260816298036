<template>
  <v-main align="center">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="10">
          <v-card class="elevation-12">
            <AlarmToolbar :title="$t('alarm.name')" />
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import AlarmToolbar from "@/components/alarm/AlarmToolbar";

export default {
  name: "Alarm",
  computed: {},
  methods: {},
  created() {},
  components: {
    AlarmToolbar
  }
};
</script>

<style>
</style>